$base-color: #3e495a;
$dark-base-color: #282d35;
$base-yellow: #ffff83;

$media-color: #6a8e6e;
$stream-color: #744696;
$clip-color: #44608b;
$external-color: #b58947;

@keyframes levitate {
  0% { margin-top: -15px }
  50% { margin-top: -35px }
  100% { margin-top: -15px }
}

@keyframes unread {
  0% { color: $base-color }
  50% { color: lighten($base-color, 33%)}
}

@keyframes pulsate {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  50% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(2.5);
    opacity: 0;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(2.5);
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@media (max-width: 1279px) {
  .container {
    * {
      display: none !important;
    }

    &:before {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      justify-content: center;
      align-items: center;
      display: flex;
      content: "Disabled for resolutions below 1280×720";
      padding: 0 25%;
      font-size: 3em;
      font-weight: bold;
      text-align: center;
      opacity: 0.5;
      color: black;
    }
  }
}

.container {
  width: 100%;
  height: 100vh;
  background: $base-color;
}

.twitch-gate {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;

  .fade-in {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 6;
    animation: fade-in 700ms;
    animation-delay: 400ms;
    animation-fill-mode: backwards;

    &.disabled {
      animation: none;
    }
  }

  &.open {
    pointer-events: none;
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -100px;
    margin-top: -15px;
    width: 200px;
    height: 200px;
    z-index: 10;
  }

  span.twitch {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -100px;
    margin-top: -15px;
    width: 200px;
    height: 200px;
    background: url('./media/img/twitch.png');
    transition: margin-top .4s ease-in-out;
    cursor: pointer;
    background-size: contain;
    z-index: 10;
    animation-name: levitate;
    animation-duration: 5s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;

    &.open {
      display: none;
    }
  }

  div.info {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -200px;
    margin-top: -275px;
    width: 400px;
    height: 200px;
    z-index: 5;
    text-align: center;
    color: white;

    &.open {
      display: none;
    }

    p {
      margin: 1em 0;
      line-height: 135%;
    }
  }

  div.top {
    background: rgb(144, 71, 255);
    position: absolute;
    top: 0;
    height: 50vh;
    width: 100%;
    transition: top 1s;
    box-shadow: 0 4px 10px black;
    z-index: 2;

    &.open {
      top: -100vh;
    }
  }

  div.bottom {
    background: rgb(144, 71, 255);
    position: absolute;
    bottom: 0;
    height: 50vh;
    width: 100%;
    transition: bottom 1s;
    z-index: 3;

    &.open {
      bottom: -100vh;
    }
  }
}

.broadcast-window {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: black;

  .media {
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;

    > img {
      height: 100%;
    }
  }

  > div {
    position: absolute;
    width: 50%;
    height: 50vh;
    z-index: 1;

    &.solo-screen {
      background: black;
      width: 100%;
      height: 100vh;
      z-index: 10;
      opacity: 0;
      pointer-events: none;
      transition: opacity .7s;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }

    &.media-screen {
      width: 100%;
      height: 100vh;
      z-index: 200;
      opacity: 1;
      pointer-events: none;
      transition: opacity .7s;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      > * {
        pointer-events: none;
      }
    }

    &.fullscreen {
      .shroud {
        opacity: 0 !important;
      }
    }

    &.stream-container,
    &.external-iframe {
      .shroud {
        pointer-events: none !important;
      }
    }

    .shroud {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      pointer-events: none; /* this used to be all, for some reason */
      box-shadow: 0 0 2px 1px black inset;
      transition: opacity .7s;
      z-index: 9999;
    }

    div:first-of-type {
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      position: relative;
    }

    &.fullscreen {
      width: 100%;
      height: 100vh;
      z-index: 100;

      & > div:first-of-type {
        /* override styles set by resizing */
        width: 100% !important;
        height: 100% !important;
      }
    }

    video,
    iframe {
      width: 100%;
      height: 100%;
    }

    video {
      background: black;
    }

    div.overlay {
      position: absolute;
      pointer-events: none;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

      > div {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;

        span {
          color: white;
          font-weight: bold;
          text-shadow:
                  -1px -1px 1px #000,
                  1px -1px 1px #000,
                  -1px 1px 1px #000,
                  1px 1px 1px #000;
          position: absolute;
          font-family: Arial;
          line-height: 50%;
          white-space: nowrap;
        }
      }
    }
  }

  div.video,
  div.clip-iframe,
  div.iframe,
  div.external-iframe,
  div.stream-container {
    transition: width .4s, height .4s, z-index .4s step-end;

    &.fullscreen {
      transition: width .4s, height .4s, z-index 0s step-start;
    }
  }

  > div:nth-of-type(1) {
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  > div:nth-of-type(2) {
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }

  > div:nth-of-type(3) {
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  > div:nth-of-type(4) {
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.dialog {
  button {
    border: 0;
    padding: 1em 1.5em;
    background: #5e718c;
    opacity: .75;
    color: #fff;
    cursor: pointer;
    outline: none;
    margin: 1em;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);

    &:hover {
      opacity: .9
    }
  }
}

.production-interface {
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .menu-bar {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 40px;
    z-index: 9999;
    display: flex;
    background: #2b2c3c;
    padding: 0 5%;
    font-size: 70%;
    font-family: Arial, sans-serif;

    .menu-items {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-grow: 1;

      .menu-item {
        position: relative;
        padding: 0 1.5em;

        > span {
          display: block;
          padding: 0 2em 0 1.5em;
          height: 40px;
          line-height: 40px;
          background: #2b2c3c;
          color: #7783ab;
          cursor: pointer;
        }

        &:hover {
          > span {
            color: lighten(#7783ab, 5%);
            background: darken(#2b2c3c, 5%);
          }
        }

        .menu-item-children {
          position: absolute;

          ul li {
            display: block;
            padding: 0 2em 0 1.5em;
            height: 30px;
            line-height: 30px;
            background: #2b2c3c;
            color: #7783ab;
            cursor: pointer;
            position: relative;

            span.label {
              white-space: nowrap;
              display: block;
              width: 100%;
              height: 100%;
              position: relative;
            }

            span.arrow {
              position: absolute;

              right: 1em;
              top: 12px;

              width: 0;
              height: 0;

              border-top: 3px solid transparent;
              border-bottom: 3px solid transparent;

              border-left: 3px solid #7783ab;
            }

            > ul {
              display: none;
            }

            &:hover {
              color: lighten(#7783ab, 5%);
              background: darken(#2b2c3c, 5%);

              span.arrow {
                border-left: 3px solid lighten(#7783ab, 5%);
              }

              ul {
                display: block;
                position: absolute;
                left: 100%;
                top: 0;
                z-index: -1;
              }
            }
          }
        }
      }
    }

    .settings {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      i.fa-cog {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #7783ab;
        cursor: pointer;
        font-size: 150%;

        &:hover {
          color: lighten(#7783ab, 5%);
          background: darken(#2b2c3c, 5%);
        }
      }
    }
  }
}

.dialog-bar {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  right: 0;
  height: 40px;
  z-index: 99999;
  background: black;
  color: $base-color;

  div {
    margin-bottom: 3px;
    width: 50px;
    text-align: center;
    cursor: pointer;

    &.messages {
      &.unread {
        i {
          animation: unread 2s;
          animation-iteration-count: infinite;
          animation-timing-function: steps(1, end);
        }
      }
    }

    &:hover {
      color: lighten($base-color, 10%);
    }
  }
}

.entity-selector {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1em;

  div {
    flex-grow: 1;
    text-align: center;
    border-top: 1px solid #2a2a2a;
    border-left: 1px solid #2a2a2a;
    border-bottom: 1px solid #161616;
    border-right: 1px solid #161616;
    background: #1f2128;
    box-sizing: content-box;
    cursor: pointer;
    font-size: 75%;
    font-weight: bold;
    height: 50px;
    line-height: 46px;
    position: relative;
    width: 25%;

    &:after {
      content: "";
      bottom: 0;
      right: 0;
      left: 0;
      position: absolute;
      display: block;
      height: 5px;
      background: #111;
    }

    &.media {
      color: $media-color;
    }

    &.clip {
      color: $clip-color;
    }

    &.stream {
      color: $stream-color;
    }

    &.external {
      color: $external-color;
    }

    &.selected,
    &:hover {
      &.media {
        color: lighten($media-color, 10%);

        &:after {
          background: lighten($media-color, 10%);
        }
      }

      &.clip {
        color: lighten($clip-color, 10%);

        &:after {
          background: lighten($clip-color, 10%);
        }
      }

      &.stream {
        color: lighten($stream-color, 10%);

        &:after {
          background: lighten($stream-color, 10%);
        }
      }

      &.external {
        color: lighten($external-color, 10%);

        &:after {
          background: lighten($external-color, 10%);
        }
      }
    }
  }
}

/**
 * - drag-and-drop media files
 * - provide 9 positionings in a box, and then apply offsets that pushes the content towards the center, vertical & horizontal.
 * - content comes in two forms: media or plain text, which will include template strings ({title})
 */

.screen-overlay-container {
  display: flex;
  position: fixed;
  width: calc(100% - 750px);
  height: calc(100% - 60px);

  .screen-overlay {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    flex-direction: column;
    padding-bottom: 50px;

    h1 {
      opacity: .3;
      margin-bottom: .5em;
    }

    div.screen {
      width: 500px;
      height: 300px;
      display: flex;
      margin: .2em;
      background: rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25) inset;
      position: relative;
      border: 1px solid #222;
      box-sizing: content-box;

      .anchor {
        width: 20px;
        height: 20px;
        background: white;
        margin-left: -10px;
        margin-top: -10px;
        position: absolute;
        left: 50%;
        top: 50%;

        &.locked {
          opacity: .5;
          border: 1px solid black;
        }
      }
    }
  }
}

.panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h3 {
    margin-bottom: .5em;
    color: #ccc;
    display: flex;
    align-self: flex-start;
  }

  .overlay-settings-container {
    display: flex;
    margin-top: .5em;
  }

  .card {
    min-width: 235px;
    margin: 0 .5em;
    background: #323846;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
    padding: .5em 1em;
    font-size: 90%;
    color: #aaa;

    input,
    select {
      width: 100px;
      background: rgba(255, 255, 255, 0.1);
      border: 0;
      color: #ddd;
    }

    input[type="text"],
    select {
      padding: 5px;
    }

    option {
      background: #1f2128;
    }

    button {
      border: 0;
      padding: .25em .5em;
      background: #5e718c;
      opacity: .75;
      color: #fff;
      cursor: pointer;
      outline: none;
      min-width: 60px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
      position: relative;
      top: 0;

      &:active {
        top: 1px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
      }

      &:hover {
        opacity: .9
      }
    }

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-basis: 100%;
      flex-grow: 1;
      height: 30px;
    }
  }
}

.layouts {
  display: flex;
  position: fixed;
  flex-direction: column;
  width: calc(100% - 750px);
  height: calc(100% - 60px);

  /* layout containers */
  .layout {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    flex-direction: column;
  }

  button.toggle {
    position: absolute;
    top: 5%;
    left: 50%;
    margin-left: -50px;
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: "Arial", sans-serif;
    cursor: pointer;
    font-size: 90%;

    &.active {
      opacity: 1;
      background: #508750;
    }
  }

  div.layout {
    > div {
      &:before {
        color: #969faf;
      }

      .one {
        &:before {
          content: "1";
        }
      }

      .two {
        &:before {
          content: "2";
        }
      }

      .three {
        &:before {
          content: "3";
        }
      }

      .four {
        &:before {
          content: "4";
        }
      }

      .solo {
        margin-bottom: 1em;

        &:before {
          content: 'FULLSCREEN';
        }
      }

      .static {
        margin-bottom: 1em;

        &:before {
          content: 'MEDIA';
        }
      }
    }
  }

  div.solo {
    div.screen {
      &:before {
        content: "Solo";
      }
    }
  }

  /* inside layout containers only */
  div.screen {
    width: 200px;
    height: 112.5px;
    display: flex;
    border-radius: 3px;
    margin: .1em;
    background: rgba(0, 0, 0, 0.3);
    position: relative;
    border: 1px solid #333;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.75);

    @media (min-width: 1440px) {
      width: 300px;
      height: 168.75px;
    }

    &:before {
      color: #999;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }

    &.contains-item {
      &:before {
        content: none !important;
      }
    }

    &:hover {
      span.edit {
        opacity: .9;
      }

      span.slider {
        opacity: .9;
      }
    }

    &:hover {
      > span.edit {
        opacity: .5;
      }
    }

    > span.edit {
      left: 8px !important;
      bottom: 8px !important;
    }

    span.edit {
      color: #222;
      cursor: pointer;
      opacity: 0.9;

      &:hover {
        opacity: 1;
      }
    }

    span.slider {
      display: flex;
      width: 60%;
      color: #222;
      cursor: pointer;
      opacity: 0.9;

      &:hover {
        opacity: 1;
      }

      span.volume-slider {
        padding: 0 1em;

        input {
          -webkit-appearance: none;
          appearance: none;
          width: 100%;
          height: 6px;
          border-radius: 3px;
          background: #2b2b2b;
          outline: none;
          opacity: 0.9;
          -webkit-transition: .2s;
          transition: opacity .2s;

          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 12px;
            height: 12px;
            background: #454545;
            cursor: pointer;
            border: 1px solid gray;
            border-radius: 2px;

            &:hover {
              border-color: #ccc;
              background: lighten(#454545, 5%);
            }
          }

          &::-moz-range-thumb {
            width: 12px;
            height: 12px;
            background: #454545;
            cursor: pointer;
            border: 1px solid gray;
            border-radius: 2px;

            &:hover {
              border-color: #ccc;
              background: lighten(#454545, 5%);
            }
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    &:not(.contains-item):before {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      color: $base-color;
      font-weight: bold;
      font-family: "Arial", sans-serif;
      font-size: 125%;
    }

    span.contained-item {
      position: absolute;
      border-radius: 3px;
      left: 5px;
      right: 5px;
      top: 5px;
      bottom: 5px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #333;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);

      &.media {
        background: $media-color;

        &:hover {
          background: lighten($media-color, 5%);
        }
      }

      &.stream {
        background: $stream-color;

        &:hover {
          background: lighten($stream-color, 5%);
        }
      }

      &.clip {
        background: $clip-color;

        &:hover {
          background: lighten($clip-color, 5%);
        }
      }

      &.external {
        background: $external-color;

        &:hover {
          background: lighten($external-color, 5%);
        }
      }

      span.inner-box {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          span.actions {
            opacity: .9;
          }
        }

        span.content {
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 1em;
        }

        span.actions {
          position: absolute;
          display: flex;
          justify-content: space-between;
          align-items: center;
          opacity: 0.9;
          transition: opacity .4s;
          width: 100%;
          padding: 6px 8px;

          &.top {
            left: 0;
            top: 0;
          }

          &.bottom {
            left: 0;
            bottom: 0;
          }

          &:hover {
            opacity: 1;
          }

          i {
            color: #222;
            display: flex;
            cursor: pointer;
            opacity: 0.9;
            width: 16px;

            &:hover {
              opacity: 1;
            }

            &.fa-volume-mute,
            &.fa-volume-up {

            }

            &.fa-play,
            &.fa-pause {
              font-size: 12px;
            }
          }
        }

        span.quality {
          width: 60px;
          text-align: center;
          font-size: 80%;
          color: black;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  span.break {
    display: flex;
    flex-basis: 100%;
  }
}

.flex-break {
  display: flex;
  flex-basis: 100%;
  height: 0;
}

.broadcast-screens {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.broadcast-settings {
  width: 100%;
  display: flex;
  margin-top: 1em;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  > div {
    display: flex;
    position: relative;
    border-top: 2px solid #2a2a2a;
    border-left: 2px solid #2a2a2a;
    border-bottom: 2px solid #161616;
    border-right: 2px solid #161616;
    background: #1f2128;
    padding: .75em 1.5em;
    min-height: 90px;
    margin: 0 .2em;
    text-align: center;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 4px;

    .title {
      color: gray;

      &.help {
        cursor: help;
      }

      > span {
        font-size: 90%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: .5em;
      }
    }
  }

  .edit-overlay {
    .button {
      text-align: center;

      button {
        padding: .5em 1em;
        background: #23609e;
        color: #d4e7fb;
        border-radius: 3px;
        position: relative;
        top: 0;
        cursor: pointer;
        border-top: 1px solid lighten(#23609e, 5%);
        border-left: 1px solid lighten(#23609e, 5%);
        border-bottom: 1px solid darken(#23609e, 5%);
        border-right: 1px solid darken(#23609e, 5%);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);

        &:active {
          top: 1px;
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
        }

        &:hover {
          background: lighten(#23609e, 5%);
          color: lighten(#d4e7fb, 5%);
        }
      }
    }
  }

  .clip-auto-mute,
  .stream-auto-play {
    width: 130px;

    .auto-mute,
    .auto-play {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    .switch-container {
      width: 100%;
      height: 30px;
      display: inline-block;
      font: 13px Arial;

      input {
        display: none;

        + label {
          height: 100%;
          width: 100%;

          > .switch {
            cursor: pointer;
            width: 100%;
            height: 100%;
            position: relative;
            background-color: #111213;
            color: white;
            transition: all 0.5s ease;
            padding: 3px;
            border-radius: 3px;
            border: 1px solid #333;
            border-top: 1px solid #111;
            border-left: 1px solid #111;

            &:before,
            &:after {
              border-radius: 2px;
              height: calc(100% - 6px);
              width: calc(50% - 3px);
              display: flex;
              align-items: center;
              position: absolute;
              justify-content: center;
              transition: all 0.3s ease;
            }

            &:before {
              background-color: white;
              color: black;
              box-shadow: 0 0 4px 4px rgba(#000, 0.2);
              left: 3px;
              z-index: 10;
              content: attr(data-unchecked);
            }

            &:after {
              right: 0;
              content: attr(data-checked);
            }
          }
        }

        &:checked + label > .switch {
          background-color: #161718;

          &:after {
            left: 0;
            content: attr(data-unchecked);
          }

          &:before {
            color: #161718;
            left: 50%;
            content: attr(data-checked);
          }
        }
      }
    }
  }

  .stream-quality {
    div {
      &.title {
        > span {
          justify-content: space-evenly;
        }
      }

      &.qualities {
        input[type="radio"] {
          display: inline-block;
          margin: .75em .5em .75em 0;

          &:first-of-type {
            margin: .75em 1.5em .75em 0;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.path {
  display: flex;

  .separator {
    opacity: .5;
  }
}

.chat {
  position: fixed;
  bottom: 0;
  overflow: hidden;
  left: 375px;
  right: 375px;
  top: calc(100%);
  transition: color .7s, top .7s;
  border-left: 1px solid #111;
  border-right: 1px solid #111;
  background: #1f2128;
  color: transparent;
  display: flex;
  flex-direction: column;

  &.open {
    color: #ccc;
    top: 40px;
    overflow: auto;
  }

  .chat-messages {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    overflow: auto;

    .messages-wrapper {
      min-height: 0;
      width: calc(100% - 4em);

      .message {
        margin: 0 2em;
        display: flex;
        width: 100%;

        a {
          color: #5ba4ff;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        &:last-of-type {
          padding-bottom: .5em;
        }

        &.first-in-chain {
          margin-top: 1.25em;
          padding-top: 1em;
          border-top: 1px solid #2c3035;

          &:first-of-type {
            border-top: 0;
          }
        }

        .author {
          font-weight: bold;
          color: #ddd;
        }

        .avatar {
          padding-right: 1em;
          box-sizing: content-box;
          min-width: 40px;
          max-width: 40px;
          max-height: 40px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        .content {
          font-size: 90%;

          .timestamp {
            opacity: .5;
            margin-left: 1em;
            font-weight: normal;
            font-size: 80%;
          }
        }
      }
    }
  }

  .chat-input-container {
    height: 100px;
    width: 100%;
    padding-bottom: 2em;
    box-sizing: content-box;

    textarea {
      margin: 1em 2em 4em;
      border-radius: 10px;
      border: 1px solid $base-color;
      background: darken($base-color, 15%);
      color: #ddd;
      padding: 1em;
      height: 100%;
      width: calc(100% - 4em);
      outline: none;

      &:focus,
      &:active {
        background: darken($base-color, 10%);
      }
    }
  }
}

.content-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 40px;
  display: flex;
  justify-content: space-evenly;

  > div {

    &.item-actions {
      left: 0;
      justify-content: space-between;
    }

    &.view-activation {
      right: 0;
      align-items: center;
      justify-content: center;
      color: #999;
      cursor: pointer;

      .view-activator {
        text-transform: uppercase;
        font-size: 70%;
        font-weight: bold;
      }

      &:hover {
        background: lighten(#1f2128, 5%);
        color: #ccc;
      }
    }

    &.view-activation,
    &.item-actions {
      position: fixed;
      height: 45px;
      width: 375px;
      display: flex;
      bottom: 0;
      border-top: 1px solid #2a2a2a;
      border-bottom: 1px solid #161616;
      background: #1f2128;

      > div {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-grow: 1;
        border-right: 1px solid #161616;
        cursor: pointer;
        width: 33.33%;
        color: #648364;

        &:last-of-type {
          color: #b87272;
        }

        &:hover {
          background: lighten(#1f2128, 5%);
          color: lighten(#648364, 10%);

          &:last-of-type {
            color: lighten(#b87272, 10%);
          }
        }
      }
    }

    &.sub-view-toggle {
      right: 0;

      > div {
        > span {
          color: #777;
          align-items: center;
          text-transform: uppercase;
          font-size: 70%;
          font-weight: bold;
        }

        &.selected,
        &:hover {
          > span {
            color: #ccc;
          }
        }
      }
    }

    &.filter {
      left: 0;

      > div {
        > span {
          &:first-of-type {
            color: #777;
            align-items: flex-end;
            text-transform: uppercase;
            font-size: 80%;
          }

          &:last-of-type {
            color: #aaa;
            text-transform: uppercase;
            font-size: 60%;
            align-items: flex-start;
            font-weight: bold;
            text-align: center;
          }

          span.direction {
            margin-left: .5em;

            &.desc {
              color: $stream-color;
            }

            &.asc {
              color: $external-color;
            }
          }
        }
      }
    }

    &.text-filter {
      left: 0;
      top: 85px;
      height: 40px;
      flex-grow: 1;
      display: flex;
      width: 375px;
      position: fixed;
      justify-content: space-between;
      border-top: 1px solid #2a2a2a;
      border-bottom: 1px solid #161616;
      background: #1f2128;
      padding: 5px 10px;

      input {
        height: 100%;
        width: 100%;
        background: gray;
        border: 1px solid black;
        text-align: center;
        outline: none;
        background: lighten(#1f2128, 5%);
        color: #ccc;

        &:focus,
        &:active {
          background: lighten(#1f2128, 10%);
        }

        &::placeholder {
          font-style: italic;
        }
      }
    }

    &.sub-view-toggle,
    &.filter {
      position: fixed;
      height: 45px;
      width: 375px;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #2a2a2a;
      border-bottom: 1px solid #161616;
      background: #1f2128;

      > div.filter-box {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-grow: 1;
        border-right: 1px solid #161616;
        cursor: pointer;
        width: 33.33%;

        &:hover {
          background: lighten(#1f2128, 5%);
        }

        > span {
          display: flex;
          flex-basis: 100%;
        }
      }
    }

    &.item-list,
    &.sub-view-container {
      min-width: 375px;
      max-width: 375px;
      background: #1f2128;
      position: fixed;
    }

    &.sub-view-container {
      font-size: 90%;
      margin-top: 45px;
      height: calc(100% - 90px);
      background: #17181c;
      right: 0;

      .sub-view {
        width: 100%;
        height: 100%;
        padding: 1em;

        &.observer {
          .description {
            border-radius: .5em;
            margin: 0 1em 1.5em;
            padding: 2em 1.5em;
            text-align: center;
            background: darken($base-color, 25%);
            border: 1px solid #111;
            box-shadow: inset 0 0 5px 1px rgba(0, 0, 0, 0.5);
            color: #778;
            font-size: 90%;

            &:hover {
              background: darken($base-color, 23%);
              color: #99a;
            }

            div {
              margin: 0 1.5em;
              line-height: 150%;

              &:not(:first-of-type) {
                margin-top: 1em;
              }
            }
          }

          .layer {
            display: flex;
            justify-content: space-evenly;
          }

          .stream-grid {
            margin: .5em 0;
            height: 43px;
            width: 75px;
            border-radius: .5em;
            background: rgba(0, 0, 0, 0.25);
            border: 1px dashed rgba(255, 255, 255, 0.1);

            &.contains-item {
              background: transparent;
              background: linear-gradient(180deg, rgba(124,76,161,1) 0%, rgba(94,52,125,1) 4%, rgba(94,52,125,1) 96%, rgba(51,30,68,1) 100%);
              border: 5px solid #111;

              &:hover {
                background: linear-gradient(180deg, rgba(134,86,171,1) 0%, rgba(104,62,135,1) 4%, rgba(104,62,135,1) 96%, rgba(61,40,78,1) 100%);
              }
            }

            .stream-container {
              font-size: 80%;
              width: 100%;
              height: 100%;
              padding: 2px;

              div.stream {
                border-radius: 5px;
                width: 100%;
                height: 100%;
                color: darken(#fff, 10%);
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                  color: white;
                }
              }
            }
          }

          .observer-modes {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            margin: .5em 0 1.5em;

            .observer-mode {
              padding: .5em 1em;
              background: #23609e;
              color: #d4e7fb;
              opacity: .75;
              border-radius: 3px;
              position: relative;
              top: 0;
              cursor: pointer;
              border-top: 1px solid lighten(#23609e, 5%);
              border-left: 1px solid lighten(#23609e, 5%);
              border-bottom: 1px solid darken(#23609e, 5%);
              border-right: 1px solid darken(#23609e, 5%);
              box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);

              &.selected {
                background: lighten(#23609e, 8%);
                color: lighten(#d4e7fb, 8%);
                opacity: 1;

                &:hover {
                  background: lighten(#23609e, 12%);
                  color: lighten(#d4e7fb, 12%);
                }
              }

              &:active {
                top: 1px;
                box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
              }

              &:hover {
                background: lighten(#23609e, 4%);
                color: lighten(#d4e7fb, 4%);
                opacity: 1;
              }
            }
          }
        }
      }
    }

    &.item-list {
      margin-top: 85px;
      height: calc(100% - 170px);
      background: #17181c;
      left: 0;
      overflow: auto;

      .items {
        font-size: 90%;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        position: relative;

        &.has-items {
          &.clip {
            &:before,
            &:after {
              background: $clip-color;
            }
          }

          &.stream {
            &:before,
            &:after {
              background: $stream-color;
            }
          }

          &.media {
            &:before,
            &:after {
              background: $media-color;
            }
          }

          &.external {
            &:before,
            &:after {
              background: $external-color;
            }
          }

          &:before {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            z-index: 1;
            left: 0;
            top: 0;
            width: 3px;
          }

          &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            z-index: 1;
            right: 0;
            top: 0;
            width: 2px;
          }
        }

        .item {
          position: relative;
          border-top: 1px solid #2a2a2a;
          border-bottom: 1px solid #161616;
          border-right: 1px solid #161616;
          padding: 0 1em;
          background: #1f2128;
          color: #ddd;
          cursor: pointer;

          /* new stuff */
          flex-grow: 1;
          display: flex;
          flex-direction: column;

          &.selected {
            background: lighten(#1f2128, 5%);
          }

          span {
            display: block;

            &.title {
              padding-top: 2px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            &.type {
              padding-bottom: 6px;
              text-transform: uppercase;
              font-size: 60%;
              font-weight: bold;
              letter-spacing: 1px;
            }
          }

          &:hover {
            background: lighten(#1f2128, 5%);
            color: #fff;

            &.media {
              &:before {
                background: lighten($media-color, 25%);
              }

              span.type {
                color: lighten($media-color, 25%);
              }
            }

            &.stream {
              &:before {
                background: lighten($stream-color, 25%);
              }

              span.type {
                color: lighten($stream-color, 25%);
              }
            }

            &.clip {
              &:before {
                background: lighten($clip-color, 25%);
              }

              span.type {
                color: lighten($clip-color, 25%);
              }
            }

            &.external {
              &:before {
                background: lighten($external-color, 25%);
              }

              span.type {
                color: lighten($external-color, 25%);
              }
            }
          }

          &:before {
            //content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: .75em;
          }

          &.not-live {
            span {
              &.title {
                color: #555 !important;
              }

              &.type {
                color: #684444 !important;
              }
            }
          }

          &.media {
            &:before {
              background: lighten($media-color, 15%);
            }

            span.type {
              color: lighten($media-color, 15%);
            }
          }

          &.stream {
            &:before {
              background: lighten($stream-color, 15%);
            }

            span.type {
              color: lighten($stream-color, 15%);
            }
          }

          &.clip {
            &:before {
              background: lighten($clip-color, 15%);
            }

            span.type {
              color: lighten($clip-color, 15%);
            }
          }

          &.external {
            &:before {
              background: lighten($external-color, 15%);
            }

            span.type {
              color: lighten($external-color, 15%);
            }
          }
        }
      }
    }

    .inner,
    .list {
      margin: 1em 0;
      padding: 0 4em;
      height: calc(100% - 2em);
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      &:hover {
        h3 {
          opacity: 1;
        }
      }

      h3 {
        color: white;
        position: absolute;
        top: -1.5em;
        font-size: 150%;
        opacity: 0;
        transition: opacity .7s;

        span {
          margin-left: .5em;
          margin-right: 1em;
        }

        span.icon {
          margin-right: .5em;
          width: 20px;
          display: inline-block;
          cursor: pointer;
        }
      }

      .content-item {
        margin: .25em;
        display: inline-block;
        font-family: "Arial", sans-serif;

        &.selected {
          div.media {
            background: lighten($media-color, 15%);
          }

          div.stream {
            background: lighten($stream-color, 15%);
          }

          div.clip {
            background: lighten($clip-color, 15%);
          }

          div.external {
            background: lighten($external-color, 15%);
          }
        }

        > div {
          color: white;
          border-radius: .25em;
          cursor: pointer;
          font-size: 90%;
          padding: .5em .75em;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);

          &.media {
            background: $media-color;

            &:hover {
              background: lighten($media-color, 5%);
            }
          }

          &.stream {
            background: $stream-color;

            &:hover {
              background: lighten($stream-color, 5%);
            }
          }

          &.clip {
            background: $clip-color;

            &:hover {
              background: lighten($clip-color, 5%);
            }
          }

          &.external {
            background: $external-color;

            &:hover {
              background: lighten($external-color, 5%);
            }
          }
        }
      }
    }
  }
}

.controls {
  position: absolute;
  left: .5em;
  bottom: .5em;
  color: white;
  opacity: 0;
  transition: opacity .4s;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

.folder-container {
  .folders {

    li {
      list-style-type: none;
      margin-left: 0;

      > ul li {
        margin-left: 1.5em;
      }
    }

    .folder {
      cursor: pointer;
      color: #eee;

      &:hover {
        color: lighten(#ddd, 10%);

        .icon {
          color: lighten(#d0af58, 10%);
        }
      }

      .icon {
        margin-right: .5em;
        color: #d0af58;
      }
    }
  }
}

.pending {
  display: inline-block;

  span {
    margin: 0 1px;
    font-weight: bold;
  }
}

.dialog-container {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;

  .dialog {
    background: $base-color;
    padding: 1.5em;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);

    .csv-import {
      width: 400px;
    }

    .item-settings,
    .entity-input {
      width: 400px;

      input {
        width: 100%;
        padding: 0.5em;
        outline: none;
        background: lighten($base-color, 20%);
        margin-bottom: 1em;
        border: 1px solid #2d3856;

        &:active, &:focus {
          background: lighten($base-color, 25%);
        }

        &::placeholder {
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }

    .item-settings {
      .option {
        border-radius: 5px;
        padding: .5em 1em;
        margin-bottom: .5em;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
        background: rgb(31,33,40);
        background: linear-gradient(180deg, #3c4154, #343848);
        border-top: 1px solid lighten(rgb(31,33,40), 10%);
        border-right: 1px solid lighten(rgb(31,33,40), 10%);
        cursor: pointer;
        font-size: 90%;

        &:last-of-type {
          margin-bottom: 0;
        }

        &:hover {
          background: rgb(36,38,45);
          background: linear-gradient(180deg, lighten(#3c4154, 5%), lighten(#343848, 5%));
          border-top: 1px solid lighten(rgb(36,38,45), 10%);
          border-right: 1px solid lighten(rgb(36,38,45), 10%);
        }

        .setting-status {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .orb {
            width: 21px;
            height: 21px;
            border-radius: 50%;
            background: #1d2231;
            margin-right: 1em;
            border-top: 1px solid #1a1d25;
            border-bottom: 1px solid #484e62;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before {
              content: "";
              display: block;
              width: 17px;
              height: 17px;
              box-sizing: border-box;
              border-radius: 50%;
              box-shadow: inset 0 0 5px 0 black;
            }
          }

          &.active {
            .orb {
              &:before {
                background: #3b8aff;
                background: radial-gradient(circle,#3b8aff 0,#1d52b3 100%)
              }
            }
          }

          &.inactive {
            .orb {
              &:before {
                content: none;
              }
            }
          }

          .text {
            color: #ddd;
            position: relative;
            bottom: 1px;
          }
        }
      }
    }

    textarea {
      width: 100%;
      min-height: 150px;
      outline: none;
      background: lighten($base-color, 20%);
      padding: 0.5em;
      margin-bottom: 1em;

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    button {
      margin: 0 1em 0 0;
    }
  }
}

.ns {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.workbench {
  display: flex;
  left: 0; right: 0;
  top: 0; bottom: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  transition-delay: 1.1s;
  transition-property: opacity;
  transition-duration: .7s;
  opacity: 0;
  pointer-events: none;
  z-index: -1;

  &.open {
    pointer-events: all;
    opacity: 1;
    z-index: 100;
  }

  .production-interface {
    .temp-utils {
      top: -4px;
      z-index: 999;
    }
  }

  .temp-utils {
    position: absolute;
    left: 0;
    top: 0;

    .temp-messages {
      margin: 1em;
    }
  }
}

.connected-users {
  .user {
    margin: 1em;
    display: flex;
    background: rgba(255, 255, 255, 0.03);
    padding: .75em;
    border-radius: 10px;
    position: relative;

    &:hover {
      background: rgba(255, 255, 255, 0.05);
    }

    a {
      color: #5ba4ff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .avatar {
      padding-right: 1em;
      box-sizing: content-box;
      min-width: 40px;
      max-width: 40px;
      max-height: 40px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .info {
      font-size: 90%;

      .name {
        font-weight: bold;
        color: #ddd;
        margin-top: .25em;
      }

      .role {
        opacity: .7;
        font-size: 90%;
        letter-spacing: .1px;
        color: #aaa;
      }
    }

    .actions {
      display: flex;
      align-self: center;
      right: 1em;
      position: absolute;

      i {
        color: #ddd;
        cursor: pointer;

        &:hover {
          color: white;
        }
      }
    }
  }
}

.login {
  display: flex;
  left: 0; right: 0;
  top: 0; bottom: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  transition: opacity .7s;

  &.room-entered {
    pointer-events: none;
    opacity: 0;
  }

  .label {
    opacity: 1;
    transition: opacity .7s;
    content: "";

    &:before {
      display: block;
      content: "Click the key icon ...";
      color: white;
      font-style: italic;
      margin-bottom: 1em;
    }

    &.focused {
      &:before {
        content: "Enter a room key ...";
      }
    }

    &.hidden {
      opacity: 0;
    }
  }

  .error-container {
    position: absolute;
    text-align: center;
    margin: 160px 10px 10px;
    color: $base-yellow;
    font-size: 14px;
    line-height: 150%;
    opacity: 0;
    width: 0;
    overflow: hidden;
  }

  .input-container {
    display: flex;
    height: 48px;
    background: white;
    width: 48px;
    border-radius: 24px;
    padding-left: 0;
    transition: all .4s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);

    .input {
      width: 100%;

      input {
        height: 48px;
        width: 100%;
        color: white;
        transition: width .4s, color .4s;
        border: 0;
        outline: none;
        background: transparent;
        font-size: 1.5em;
        line-height: 1.5em;

        &:focus {
          width: 300px;
          color: #333;
        }
      }
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 48px;
      height: 48px;
      border-radius: 24px;
      background: white;
      position: relative;
      cursor: pointer;
      margin-left: 0;
      transition: margin-left .4s;

      &:hover {
        i {
          opacity: 0.75;
        }
      }

      i {
        color: $base-color;
      }
    }
  }
}

.init-streams {
  .init-button {
    position: relative;
    margin-top: 125px;
    margin-bottom: 25px;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-sizing: content-box;
    display: block;
    width: 32px;
    height: 44px;
    border-radius: 50%;
    padding: 18px 20px 18px 28px;
    cursor: pointer;
  }


  .init-button:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: #2d374f;
    border-radius: 50%;
    animation: pulsate 4000ms ease-out infinite;
  }

  .init-button:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: #262f43;
    border-radius: 50%;
    transition: all 200ms;
  }

  .init-button:hover:after {
    background-color: lighten(#262f43, 5%);
  }

  .init-button span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: 32px solid #fff;
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
  }
}

div.player-controls {
  display: none !important;
}

div.interactable {
  iframe {
    pointer-events: all;
  }
}

iframe {
  /*pointer-events: none;*/
  border: none;
}

textarea {
  resize: none;
}

::-webkit-scrollbar {
  background: $base-color;
}

::-webkit-scrollbar-thumb {
  background: darken($base-color, 10%);
}